import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../store/AppContextProvider";
import "./phenode-select.modules.css";

interface PhenodeSelectProps {
    deviceList: any[];
    downloadDataPage?: boolean;
}

export default function PhenodeSelect(props: PhenodeSelectProps) {
    const { deviceList, downloadDataPage } = props;
    
    const { selectedDevice, setSelectedDevice } = useAppContext();

    const navigate = useNavigate();

    const updateSelectedDevice = (event: any) => {
        setSelectedDevice(event.target.value);
        if (!downloadDataPage) {
            navigate("/real-time");
        }
    };
    
    return (
        <div id="phenode-select" className="phenode-select w-form">
            <form id="email-form" className="form-17">
                <select id="FieldDock-Select" name="FieldDock-Select-2" className="select-field-3 w-select" onChange={updateSelectedDevice} >
                    <option style={{background: "#3898ec"}} value="">Select PheNode...</option>
                    {deviceList.map((device) => (
                        <option style={{background: "#3898ec"}} key={device.externalDeviceId} value={device.externalDeviceId} selected={selectedDevice === device.externalDeviceId ? true : false}>
                            {device.label}
                        </option>
                    ))}
                </select>
            </form>
        </div>
    );
}
