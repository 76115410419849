import React from "react";
import "./screen-title.modules.css";

export default function ScreenTitle() {
    return (
        <div id="screen-title" className="screen-title download-data">
            <div id="screen-title__text" className="text-block-30">
                Settings
            </div>
        </div>
    );
}
