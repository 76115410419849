export const fetcher = async (input: RequestInfo, init: RequestInit, ...args: any[]) => {
    const res = await fetch(input, init);
    return res.json();
};

export const fetcherWithToken = async (url: string, token: string, method: string, body: any) => {
    const response = await fetch(url, {
        method: method ? "GET" : method,
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    });
    // console.info(response);
    const res = await response.json();
    //console.info(res);
    return res;
};
