import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useState } from "react";
import { fetchUserPreferences, updateUserPreferences } from "../../../services/api/api";
import { useAppContext } from "../../../store/AppContextProvider";
import SubmitButton from "../../settings/SubmitButton";
import PreferencesDropdown from "../PreferencesDropdown";


export default function DataDownloadPreferencesContainer() {

    const { keycloak } = useKeycloak();
    const { dataDownloadPreferences, setDataDownloadPreferences } = useAppContext();
    const [ loading, setLoading ] = useState(false);

    const saveDataDownloadPreferences = async (data: any) => {
        if (dataDownloadPreferences === null || dataDownloadPreferences === undefined) {
            return;
        }
        const { blankCells, dateAndTimeFormat, decimalPlaces, errorValues, hyphens, zeroValues, downsample } = dataDownloadPreferences;
        const response = await updateUserPreferences(keycloak.token, { blankCells, dateAndTimeFormat, decimalPlaces, errorValues, hyphens, zeroValues, downsample } || {});

        if (response.status === 200) {
            alert("Updated User Preferences");
        } else {
            alert("Error Updating User Preferences");
        }
    };

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const response = await fetchUserPreferences(keycloak.token);
            if (response.status === 200) {
                // If there is an error, set the default preferences
                const data = await response.json();
                setDataDownloadPreferences({...data.dataDownloadPreferences, time: new Date()});  // This forces the preferences to actually trigger an update in the context
            }
            if (response.status === 404) {
                setDataDownloadPreferences({time: new Date()});  // This forces the preferences to actually trigger an update in the context
            }
        };
        fetchData().catch((e) => {
            console.log("Error fetching user preferences", e);
        });
        setLoading(false);
    }, []);


    const hyphensOptions = [
        {
            key: 0,
            value: "Replace with underscore",
        },
        {
            key: 1,
            value: "Leave hyphen",
        },
        {
            key: 2,
            value: "Delete hypen",
        },
    ];

    const errorValuesOptions = [
        {
            key: 0,
            value: "Replace with zero",
        },
        {
            key: 1,
            value: "Leave error",
        },
        {
            key: 2,
            value: "Delete and leave cell blank",
        },
        {
            key: 3,
            value: "Impute with mean substitution",
        },
        {
            key: 4,
            value: "Flag with custom value",
        },
    ];

    const blankCellsOptions = [
        {
            key: 0,
            value: "Replace with zero",
        },
        {
            key: 1,
            value: "Leave blank and do nothing",
        },
        {
            key: 2,
            value: "Impute with mean substitution",
        },
        {
            key: 3,
            value: "Flag with custom value",
        },
    ];

    const zeroOptions = [
        {
            key: 0,
            value: "Leave zero",
        },
        {
            key: 1,
            value: "Delete and leave blank cell",
        },
        {
            key: 2,
            value: "Impute with mean substitution",
        },
        {
            key: 3,
            value: "Flag with custom value",
        },
    ];

    const timezoneOptions = [
        {
            key: 0,
            value: "Coordinated Universal Time (GMT)",
        },
        {
            key: 1,
            value: "Eastern Standard Time (GMT-5)",
        },
        {
            key: 2,
            value: "Central Standard Time (GMT-6)",
        },
        {
            key: 3,
            value: "Mountain Standard Time (GMT-7)",
        },
        {
            key: 4,
            value: "Pacific Standard Time (GMT-8)",
        },
    ];

    const decimalsOptions = [
        {
            key: 0,
            value: "0",
        },
        {
            key: 1,
            value: "1",
        },
        {
            key: 2,
            value: "2",
        },
        {
            key: 3,
            value: "3",
        },
        {
            key: 4,
            value: "4",
        },
        {
            key: 5,
            value: "No limit"
        }
    ];

    const downsampleOptions = [
        {
            key: 0,
            value: "No downsampling",
        },
        {
            key: 1,
            value: "Maximum 1MB per file",
        },
        {
            key: 2,
            value: "Flag with custom value",
        },
    ];

    if (loading) {
        return <div className="loading-text">Loading...</div>;
    }

    return (
        <div id="w-node-_42b39292-4754-85b9-cc79-90857dda130e-d5ac754d" className="data-preferences-div-block gradient">
            <div id="w-node-_09bd8512-3f6f-26df-9208-73a96dba00b5-d5ac754d" className="drop-downs">
                <PreferencesDropdown setting={"errorValues"} label={"In the case of error values"} options={errorValuesOptions}></PreferencesDropdown>
                <PreferencesDropdown setting={"blankCells"} label={"In the case of blank cells"} options={blankCellsOptions}></PreferencesDropdown>
                <PreferencesDropdown setting={"hyphens"} label={"In the case of hyphens"} options={hyphensOptions}></PreferencesDropdown>
            </div>
            <div id="w-node-_851afc06-8327-eece-34b2-fb7648dddc96-d5ac754d" className="drop-downs">
                <PreferencesDropdown setting={"zeroValues"} label={"In the case of zero values"} options={zeroOptions}></PreferencesDropdown>
                <PreferencesDropdown setting={"decimalPlaces"} label={"Desired number of decimal places"} options={decimalsOptions}></PreferencesDropdown>
                <PreferencesDropdown setting={"dateAndTimeFormat"} label={"Select time zone to display"} options={timezoneOptions}></PreferencesDropdown>
            </div>
            <div id="w-node-_851afc06-8327-eece-34b2-fb7b48dbdc96-d5ac754d" className="drop-downs">
                <PreferencesDropdown setting={"downsample"} label={"Downsample image size"} options={downsampleOptions} invisibleCustomValue={true}></PreferencesDropdown>
                <SubmitButton sx={{width: "300px"}} label={"Update Preferences"} onClick={() => saveDataDownloadPreferences(dataDownloadPreferences)}></SubmitButton>
            </div>
        </div>
    );
}
