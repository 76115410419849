export const API_URL = process.env.REACT_APP_BACKEND_URL;
export const SERVER_IP = process.env.REACT_APP_PHENODE_SERVER_IP;
export const DASHBOARD_ID = process.env.REACT_APP_GRAFANA_DASHBOARD;
export const WIRELESS_DASHBOARD_ID = process.env.REACT_APP_GRAFANA_WIRELESS_DASHBOARD;
export const TOKEN_EXPIRATION = Number(process.env.REACT_APP_TOKEN_EXPIRATION) || 4 * 60;
export const KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM || "";
export const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID || "";

export const updateDevice = async (id: string, userToken: string, body: any) => {
    const response = await fetch(`${API_URL}/devices/${id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${userToken}` },
        body: JSON.stringify(body),
    });
    return response;
};

export const fetchDeviceSensorData = async (id: string, userToken: string, from: string, to: string, body: object) => {
    const response = await fetch(`${API_URL}/devices/${id}/sensor-data/${from}/${to}`, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${userToken}` },
        body: JSON.stringify(body),
    });
    return response;
};

export const fetchDeviceHealthData = async (id: string, userToken: string, from: string, to: string, body: object) => {
    const response = await fetch(`${API_URL}/devices/${id}/health-data/${from}/${to}`, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${userToken}` },
        body: JSON.stringify(body),
    });
    return response;
};

export const fetchWirelessSensorData = async (sensorList: string, userToken: string, from: string, to: string, body: object) => {
    const response = await fetch(`${API_URL}/wireless-sensors/sensor-data/${sensorList}/${from}/${to}`, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${userToken}` },
        body: JSON.stringify(body),
    });
    return response;
};

export const fetchAllDeviceData = async (id: string, userToken: string, from: string, to: string, body: object) => {
    const response = await fetch(`${API_URL}/devices/${id}/all-data/${from}/${to}`, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${userToken}` },
        body: JSON.stringify(body),
    });
    return response;
};

export const fetchAllImages = async (id: string, userToken: string, from: string, to: string, body: object) => {
    const response = await fetch(`${API_URL}/devices/${id}/images/download/${from}/${to}`, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${userToken}` },
        body: JSON.stringify(body),
    });
    return response;
};

export const fetchUserPreferences = async (userToken: string | undefined) => {
    const response = await fetch(`${API_URL}/user-preferences`, {
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${userToken}` },
    });
    return response;
};

export const updateUserPreferences = async (userToken: string | undefined, body: object) => {
    const response = await fetch(`${API_URL}/user-preferences`, {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${userToken}` },
        body: JSON.stringify(body),
    });
    return response;
};

export const setDeviceWifiCredentials = async (id: string, userToken: string, body: object) => {
    const response = await fetch(`${API_URL}/devices/${id}/environment-variables`, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${userToken}` },
        body: JSON.stringify(body),
    });
    return response;
};

export const setDeviceImageCaptureInterval = async (id: string, userToken: string, body: object) => {
    const response = await fetch(`${API_URL}/devices/${id}/environment-variables`, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${userToken}` },
        body: JSON.stringify(body),
    });
    return response;
};

export const setDeviceImageCaptureTime = async (id: string, userToken: string, body: object) => {
    const response = await fetch(`${API_URL}/devices/${id}/environment-variables`, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${userToken}` },
        body: JSON.stringify(body),
    });
    return response;
};

export const resetDevice = async (id: string, userToken: string, body: object) => {
    const response = await fetch(`${API_URL}/devices/${id}/environment-variables`, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${userToken}` },
        body: JSON.stringify(body),
    });
    return response;
};

export const updateSensor = async (id: string, userToken: string, body: any) => {
    console.log("Updating sensor with body: ", body);
    console.log("Sensor id: ", id);
    const response = await fetch(`${API_URL}/wireless-sensors/${id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${userToken}` },
        body: JSON.stringify(body),
    });
    return response;
};
