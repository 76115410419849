import React from "react";

interface BatteryAndSoilSensorProps {
    battery: {
        batteryPercent: number | undefined;
    } | undefined;
    soilMoisture1: number | undefined;
    soilMoisture2: number | undefined;
    soilSensor1Health: string | undefined;
    soilSensor2Health: string | undefined;
}

export default function BatteryAndSoilSensor(props: BatteryAndSoilSensorProps) {
    const { battery, soilMoisture1, soilSensor1Health, soilMoisture2, soilSensor2Health } = props;

    return (
        <div id="w-node-_6bce96a8-f4f9-2919-86d1-b82d57d02271-71f130de" className="w-layout-grid system-measurements-ws wsn w-clearfix" style={{gridRowGap: "0.5rem"}}>
            <div id="w-node-_84a770d4-d93e-1bc2-3fa3-abd7dc18f2e3-71f130de" className="value-label">Battery:</div>
            <div id="w-node-_3f758dd9-38f3-3b52-1719-fee5b8ee9581-71f130de" className="value rj">{battery?.batteryPercent ? battery.batteryPercent + " %" : null}</div>
            <div id="w-node-_897d38e1-278a-9d40-0f5d-371ab296af1e-71f130de" className="value-label">Soil Moisture 1:</div>
            <div id="w-node-_897d38e1-278a-9d40-0f5d-371ab296af20-71f130de" className="value rj">{soilMoisture1 ? soilMoisture1 + " %" : null}</div>
            <div id="w-node-_18056379-ca46-1c9e-f0df-a9b4f627ee35-71f130de" className="value-label">Sensor Health:</div>
            <div id="w-node-df84e779-367c-bc17-8ff6-224e2fc01dc5-71f130de" className={`value rj ${soilSensor1Health === "Offline" ? "orange" : ""}`}>{soilSensor1Health}</div>
            <div id="w-node-aea83274-8f6a-8ad3-dc0a-ba52be0e421f-71f130de" className="value-label">Soil Moisture 2:</div>
            <div id="w-node-aea83274-8f6a-8ad3-dc0a-ba52be0e4221-71f130de" className="value rj">{soilMoisture2 ? soilMoisture2 + " %" : null}</div>
            <div id="w-node-aea83274-8f6a-8ad3-dc0a-ba52be0e422b-71f130de" className="value-label">Sensor Health:</div>
            <div id="w-node-aea83274-8f6a-8ad3-dc0a-ba52be0e422d-71f130de" className={`value rj ${soilSensor2Health === "Offline" ? "orange" : ""}`}>{soilSensor2Health}</div>
        </div>
    );
}