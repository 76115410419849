import "./log-out-button.modules.css";
import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useAppContext } from "../../../store/AppContextProvider";
import SubmitButton from "../../../features/settings/SubmitButton";

export default function LogOutButton() {
    const { keycloak } = useKeycloak();
    const { setDeviceList } = useAppContext();

    function onLogout() {
        setDeviceList([]);
        keycloak.logout({
            redirectUri: "https://grafana.phenode.cloud/logout"
        });
    }
    
    return (
        <>
            <SubmitButton label={"Log Out"} id="log-out-button" onClick={onLogout} className="log-out-button w-button"></SubmitButton>
        </>
    );
}
