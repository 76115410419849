import { styled, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppContext } from "../../../store/AppContextProvider";

interface DropdownOption {
    key: number;
    value: string;
    customValue?: string;
}

interface PreferencesProps {
    label: string;
    setting: string;
    options: DropdownOption[];
    invisibleCustomValue?: boolean;
}

export default function PreferencesDropdown(props: PreferencesProps) {
    const { label, options, setting, invisibleCustomValue } = props;

    const { dataDownloadPreferences, setDataDownloadPreferences } = useAppContext();

    const [selectedOption, setSelectedOption] = useState<DropdownOption | null | any>({});
    const [customValue, setCustomValue] = useState(false);

    const updateSelectedOption = (event: any) => {
        const newPref = dataDownloadPreferences;
        newPref[setting] = { key: event.target.options.selectedIndex, value: event.target.value };
        setSelectedOption(newPref[setting]);
        setDataDownloadPreferences({...newPref, time: new Date()});  // This forces the preferences to actually trigger an update in the context
    };

    const CssTextField = styled(TextField)({
        "& label.Mui-focused": {
            color: "#1a75e0",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#1a75e0",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#1a75e0",
            },
            "&:hover fieldset": {
                borderColor: "#1a75e0",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#1a75e0",
            },
        },
        "& input": {
            color: "#48f7f5",
            // fontSize: "12px",
        },
        "& label": {
            color: "#48f7f5",
            // fontSize: "12px",
        },
    });

    // Load preferences for selected setting
    useEffect(() => {
        if (dataDownloadPreferences[setting] === null || dataDownloadPreferences[setting] === undefined) {
            const newPref = dataDownloadPreferences;
            newPref[setting] = { key: 0, value: options[0] };
            setSelectedOption(dataDownloadPreferences[setting]);
            setDataDownloadPreferences({...newPref, time: new Date()});  // This forces the preferences to actually trigger an update in the context
        }
        else {
            if ("value" in dataDownloadPreferences[setting]) {
                setSelectedOption(dataDownloadPreferences[setting]);
            }
        }
    }, [setting, dataDownloadPreferences]);

    useEffect(() => {
        options.forEach((opt) => {
            if (opt.value === "Flag with custom value") {
                // showCustomValue = true;
                setCustomValue(true);
            }
        });
    }, [options]);

    const handleInputChange = (event: any) => {
        const newPref = dataDownloadPreferences;
        newPref[setting].customValue = event.target.value;
        setDataDownloadPreferences(newPref);
    };

    if (!selectedOption.value) {
        return <div></div>;
    }
    return (
        <div>
            <div className="text-block-33" style={{ width: "300px" }}>
                {label}
            </div>
            <form>
                <select
                    style={{ width: "100%" }}
                    id="FieldDock-Select"
                    name="FieldDock-Select-2"
                    className="wireless-sensor-drop-down w-node-_7e038e6b-55de-3fb3-ba81-3a5b04326b6c-d5ac754d w-select"
                    onChange={updateSelectedOption}
                    defaultValue={selectedOption !== null ? selectedOption.value : ""}>
                    {options.map((opt) => (!invisibleCustomValue || opt.value !== "Flag with custom value") ? (
                        <option key={opt.key} value={opt.value}>
                            {opt.value}
                        </option>
                    ): null)}
                </select>
            </form>
            {customValue && (
                <CssTextField
                    disabled={selectedOption.value !== "Flag with custom value"}
                    style={{ marginTop: "10px", width: "100%", visibility: invisibleCustomValue ? "hidden" : "visible" }}
                    size="small"
                    label={"Custom value"}
                    onChange={handleInputChange}
                    value={selectedOption !== null ? selectedOption.customValue : ""}
                />
            )}
        </div>
    );
}
