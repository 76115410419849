import React from "react";

interface WindDataProps {
    windSpeed: number | undefined;
    windGustSpeed: number | undefined;
    windDirection: number | undefined;
    sensorHealth: string | undefined;
}

function mapWindDirection(windSpeed: number | undefined) {
    // Map wind direction from degrees into compass directions
    if (windSpeed === undefined) {
        return "";
    }

    if (windSpeed >= 348.75 || windSpeed < 11.25) {
        return "N";
    }
    if (windSpeed >= 11.25 && windSpeed < 33.75) {
        return "NNE";
    }
    if (windSpeed >= 33.75 && windSpeed < 56.25) {
        return "NE";
    }
    if (windSpeed >= 56.25 && windSpeed < 78.75) {
        return "ENE";
    }
    if (windSpeed >= 78.75 && windSpeed < 101.25) {
        return "E";
    }
    if (windSpeed >= 101.25 && windSpeed < 123.75) {
        return "ESE";
    }
    if (windSpeed >= 123.75 && windSpeed < 146.25) {
        return "SE";
    }
    if (windSpeed >= 146.25 && windSpeed < 168.75) {
        return "SSE";
    }
    if (windSpeed >= 168.75 && windSpeed < 191.25) {
        return "S";
    }
    if (windSpeed >= 191.25 && windSpeed < 213.75) {
        return "SSW";
    }
    if (windSpeed >= 213.75 && windSpeed < 236.25) {
        return "SW";
    }
    if (windSpeed >= 236.25 && windSpeed < 258.75) {
        return "WSW";
    }
    if (windSpeed >= 258.75 && windSpeed < 281.25) {
        return "W";
    }
    if (windSpeed >= 281.25 && windSpeed < 303.75) {
        return "WNW";
    }
    if (windSpeed >= 303.75 && windSpeed < 326.25) {
        return "NW";
    }
    if (windSpeed >= 326.25 && windSpeed < 348.75) {
        return "NNW";
    }
    return "";
}

export default function WindSensor(props: WindDataProps) {
    const { windSpeed, windGustSpeed, windDirection, sensorHealth } = props;

    return (
        <div id="w-node-_92109223-5a77-4ab2-55c6-e769e6119f89-07ac755a" className="wind-sensor-measurements w-clearfix">
            <div id="w-node-_92109223-5a77-4ab2-55c6-e769e6119f8a-07ac755a" className="value-label">
                Wind Speed:
            </div>
            <div id="w-node-_92109223-5a77-4ab2-55c6-e769e6119f8c-07ac755a" className="value lj">
                {/* {windSpeed ? windSpeed + "km/h" : ""} */}
                {windSpeed ? (windSpeed * 0.621371192).toFixed(2) + " mph" : ""}
            </div>
            <div id="w-node-e7cc79ba-9831-a56d-79da-144584665107-07ac755a" className="value-label">
                Wind Gust:
            </div>
            <div id="w-node-ae927d96-5319-56d4-3500-c79231211ea3-07ac755a" className="value lj">
                {/* {windGustSpeed ? windGustSpeed + "km/h" : ""} */}
                {windGustSpeed ? (windGustSpeed * 0.621371192).toFixed(2) + " mph" : ""}
            </div>
            <div id="w-node-_68366d52-7179-ddac-1915-183af6c7effa-07ac755a" className="value-label">
                Wind Direction:
            </div>
            <div id="w-node-_2348d44b-c88d-7eb5-6587-c3a8486af221-07ac755a" className="value lj">
                {windDirection ? mapWindDirection(windDirection) : ""}
            </div>
            <div id="w-node-_92109223-5a77-4ab2-55c6-e769e6119f8e-07ac755a" className="value-label">
                Sensor Health:
            </div>
            <div
                id="w-node-_92109223-5a77-4ab2-55c6-e769e6119f90-07ac755a"
                className={`value lj ${sensorHealth === "Offline" ? "orange" : ""} ${sensorHealth === "Check" ? "magenta" : ""}`}>
                {sensorHealth ? sensorHealth : ""}
            </div>
        </div>
    );
}
