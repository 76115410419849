import React, { useState, createContext, useContext, useEffect } from "react";
import { Device } from "../utils/typesAndInterfaces";

const AppContext = createContext<any>(null);

const dataDownloadPref = {
    zeroValues: {},
    blankCells: {},
    errorValues: {},
    hyphens: {},
    decimalPlaces: {},
    dateAndTimeFormat: {},
};

interface DataDownloadPrefIFace {
    zeroValues: any;
    blankCells: any;
    errorValues: any;
    hyphens: any;
    decimalPlaces: any;
    dateAndTimeFormat: any;
}


export const AppContextProvider = ({ children }: any) => {
    const [devices, setDevices] = useState<Array<Device>>([]);

    const [deviceList, setDeviceList] = useState(null);
    const [selectedDevice, setSelectedDevice] = useState(null);
    
    const [wirelessSensorList, setWirelessSensorList] = useState(null);
    const [selectedWirelessSensor, setSelectedWirelessSensor] = useState(null);

    const [dataDownloadPreferences, setDataDownloadPreferences] = useState<DataDownloadPrefIFace | null>(dataDownloadPref);


    // Load deviceList from local storage
    useEffect(() => {
        if (sessionStorage.getItem("deviceList")) {
            const devicesList = sessionStorage.getItem("deviceList");
            if (devicesList !== null && devicesList !== undefined) {
                try {                   
                    setDeviceList(JSON.parse(devicesList));
                }
                catch (e) {
                    console.log("Error parsing device list from local storage", e);
                }                
            }
        }
        else {
            console.log("Setting empty device list");
        }
    }, []);

    // Save deviceList to local storage
    useEffect(() => {
        if (deviceList !== null && deviceList !== undefined) {           
            sessionStorage.setItem("deviceList", JSON.stringify(deviceList));
        }
    }, [deviceList]);

    // Save selected device to local storage
    useEffect(() => {
        if (sessionStorage.getItem("selectedDevice")) {
            const selectedDevice = sessionStorage.getItem("selectedDevice");
            if (selectedDevice !== null && selectedDevice !== undefined) {
                try {                   
                    setSelectedDevice(JSON.parse(selectedDevice));
                }
                catch (e) {
                    console.log("Error parsing selected device from local storage", e);
                }
            }
        }
    }, []);

    useEffect(() => {
        if (selectedDevice !== null && selectedDevice !== undefined) {           
            sessionStorage.setItem("selectedDevice", JSON.stringify(selectedDevice));
        }
    }, [selectedDevice]);

    /* Wireless sensor section */
    // Load wirelessSensorList from local storage
    useEffect(() => {
        if (sessionStorage.getItem("wirelessSensorList")) {
            const devicesList = sessionStorage.getItem("wirelessSensorList");
            if (devicesList !== null && devicesList !== undefined) {
                try {                   
                    setWirelessSensorList(JSON.parse(devicesList));
                }
                catch (e) {
                    console.log("Error parsing wireless sensor list from local storage", e);
                }                
            }
        }
        else {
            console.log("Setting empty wireless sensor list");
        }
    }, []);

    // Save wirelessSensorList to local storage
    useEffect(() => {
        if (wirelessSensorList !== null && wirelessSensorList !== undefined) {           
            sessionStorage.setItem("wirelessSensorList", JSON.stringify(wirelessSensorList));
        }
    }, [wirelessSensorList]);

    // Save selected wireless sensor to local storage
    useEffect(() => {
        if (sessionStorage.getItem("selectedWirelessSensor")) {
            const selectedWirelessSensor = sessionStorage.getItem("selectedWirelessSensor");
            if (selectedWirelessSensor !== null && selectedWirelessSensor !== undefined) {
                try {                   
                    setSelectedWirelessSensor(JSON.parse(selectedWirelessSensor));
                }
                catch (e) {
                    console.log("Error parsing selected wireless sensor from local storage", e);
                }
            }
        }
    }, []);

    useEffect(() => {
        if (selectedWirelessSensor !== null && selectedWirelessSensor !== undefined) {           
            sessionStorage.setItem("selectedWirelessSensor", JSON.stringify(selectedWirelessSensor));
        }
    }, [selectedWirelessSensor]);

    return (
        <AppContext.Provider
            value={{
                devices,
                setDevices,
                deviceList,
                setDeviceList,
                selectedDevice,
                setSelectedDevice,
                wirelessSensorList,
                setWirelessSensorList,
                selectedWirelessSensor,
                setSelectedWirelessSensor,
                dataDownloadPreferences,
                setDataDownloadPreferences
            }}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => useContext(AppContext);

export default AppContext;
