import React from "react";

interface airSensorProps {
    temperature: number | undefined;
    humidity: number | undefined;
    airPressure: number | undefined;
    vaporPressure: number | undefined;
    sensorHealth: string | undefined;
}

export default function AmbientAir(props: airSensorProps) {
    const { temperature, humidity, airPressure, vaporPressure, sensorHealth } = props;

    return (
        <div id="w-node-c1662213-68a6-49f1-eaf1-e90dd794aa1b-07ac755a" className="ambient-air-measurements w-clearfix">
            <div id="w-node-c1662213-68a6-49f1-eaf1-e90dd794aa1c-07ac755a" className="value-label">
                Temperature:
            </div>
            <div id="w-node-c1662213-68a6-49f1-eaf1-e90dd794aa1e-07ac755a" className="value lj">
                {/* {temperature ? temperature + "°C" : ""} */}
                {temperature ? (temperature * 1.8 + 32).toFixed(2) + " F" : ""}
            </div>
            <div id="w-node-c1662213-68a6-49f1-eaf1-e90dd794aa20-07ac755a" className="value-label">
                Humidity:
            </div>
            <div id="w-node-c1662213-68a6-49f1-eaf1-e90dd794aa22-07ac755a" className="value lj">
                {humidity ? humidity + " %" : ""}
            </div>
            <div id="w-node-c1662213-68a6-49f1-eaf1-e90dd794aa24-07ac755a" className="value-label">
                Air Pressure:
            </div>
            <div id="w-node-c1662213-68a6-49f1-eaf1-e90dd794aa26-07ac755a" className="value lj">
                {airPressure ? airPressure + " kPa" : ""}
            </div>
            <div id="w-node-c1662213-68a6-49f1-eaf1-e90dd794aa28-07ac755a" className="value-label">
                Vapor Pressure:
            </div>
            <div id="w-node-c1662213-68a6-49f1-eaf1-e90dd794aa2a-07ac755a" className="value lj">
                {vaporPressure ? vaporPressure + " kPa" : ""}
            </div>
            <div id="w-node-cf9373c4-4c9d-9017-40e1-e567f7c70ca2-07ac755a" className="value-label">
                Sensor Health:
            </div>
            <div
                id="w-node-_208bca55-4250-b010-4524-b2963e5383a9-07ac755a"
                className={`value lj ${sensorHealth === "Offline" ? "orange" : ""} ${sensorHealth === "Check" ? "magenta" : ""}`}>
                {sensorHealth ? sensorHealth : ""}
            </div>
        </div>
    );
}
