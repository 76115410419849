import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/Layout";
import NavBlock from "../../components/NavBlock";
import { useDevice, useMySensors, useWirelessSensor } from "../../services/api/swrHooks";
import { Device, WirelessSensor } from "../../utils/typesAndInterfaces";
import { useAppContext } from "../../store/AppContextProvider";

import { formatGpsData, mapWirelessSensors } from "../../utils/helperFunctions";

import { useKeycloak } from "@react-keycloak/web";

import "./wireless-sensor-modules.css";

import ScreenTitle from "./components/ScreenTitle";
import WirelessSensorSelect from "./components/WirelessSensorSelect";

import WirelessSensorGraphic from "../../assets/images/Wireless-Sensors-v4.svg";
import GrafanaActiveIcon from "../../assets/images/Grafana-Icon_Active.svg";
import SubmitButton from "../settings/SubmitButton";
import { styled, TextField } from "@mui/material";
import { SERVER_IP, updateSensor, WIRELESS_DASHBOARD_ID } from "../../services/api/api";
import Grafana from "./components/Grafana";
import ScreenToggleButton from "../../components/NavBlock/ScreenToggleButtons/ScreenToggleButton";
import Gps from "./components/Gps";
import AirSensor from "./components/AirSensor";
import BatteryAndSoilSensor from "./components/BatteryAndSoilSensor";

const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
        color: "#1a75e0",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1a75e0",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1a75e0",
        },
        "&:hover fieldset": {
            borderColor: "#1a75e0",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1a75e0",
        },
    },
    "& input": {
        color: "#48f7f5",
        // fontSize: "12px",
    },
    "& label": {
        color: "#48f7f5",
        // fontSize: "12px",
    },
});

export default function WirelessSensorDataPage() {
    const { selectedWirelessSensor, setSelectedWirelessSensor, selectedDevice, wirelessSensorList, setWirelessSensorList } = useAppContext();
    const { keycloak } = useKeycloak();

    const [token, setToken] = useState("");
    const [sensor, setSensor] = useState<WirelessSensor | null>(null);
    const [device, setDevice] = useState<Device | null>(null);
    const [tempName, setTempName] = useState("");
    const [newName, setNewName] = useState("");
    const [settingName, setSettingName] = useState<boolean>(false);
    const [doneSettingName, setDoneSettingName] = useState<boolean>(false);
    const [grafanaUrl, setGrafanaUrl] = useState<string>("");

    const { sensorsData, sensorsLoading, sensorsError, mutate } = useMySensors(token);
    const { sensorData, sensorLoading, sensorError } = useWirelessSensor(token, selectedWirelessSensor);
    const { data, deviceIsLoading, deviceIsError } = useDevice(token, selectedDevice);

    useEffect(() => {
        if (keycloak.token) {
            setToken(keycloak.token);
        }
    }, [keycloak.token]);

    useEffect(() => {
        if (data) {
            setDevice(data.device);
        }
    }, [data]);

    useEffect(() => {
        if (sensorsData) {
            // sort the wireless sensors, put selected sensor in first place
            // remove the selectedWirelessSensor from the list of sensors
            let sensors = sensorsData.sensors.filter((sensor: any) => sensor.externalSensorId !== selectedWirelessSensor);
            if (selectedWirelessSensor) {
                // Add the selectedWirelessSensor to the beginning of the list of sensors
                sensors.unshift(sensorsData.sensors.filter((sensor: any) => sensor.externalSensorId === selectedWirelessSensor)[0]);
            }
            setWirelessSensorList(sensors);

            sensors = [sensors[0]];

            // NOTE: The order of the sensors does NOT reflect on the Grafana dashboard, as it appears to be sorted automatically
            const sensorsUrl = sensors ? "&var-sensor=" + sensors.map((sensor: any) => sensor.externalSensorId).join("&var-sensor=") : "&var-sensor=";
            const grafanaUrl = `https://grafana.phenode.cloud/${WIRELESS_DASHBOARD_ID}?orgId=1&kiosk=tv&auth_token=${keycloak.token}&refresh=30m&from=now-6h&to=now${sensorsUrl}`;
            setGrafanaUrl(grafanaUrl);
        }
    }, [sensorsData, selectedWirelessSensor]);

    useEffect(() => {
        if (sensorData) {
            if (sensorData.sensor?.label) {
                setTempName(sensorData.sensor?.label);
            } else {
                setTempName(sensorData.sensor?.externalSensorId);
            }

            if (sensorData.sensor) {
                //console.log(sensorData.sensor);
                setSensor(sensorData.sensor);
                //console.log(sensorData);
            }
        }
    }, [sensorData]);

    useEffect(() => {
        if (newName !== "" && newName !== sensor?.label) {
            const fetchData = async () => {
                setSettingName(true);
                const data = await updateSensor(selectedWirelessSensor, token, { label: newName });
                const resp = await data.json();
                if (resp.success) {
                    await mutate();
                    setTempName(newName);
                    setSelectedWirelessSensor(selectedWirelessSensor);
                }
                setSettingName(false);
                setDoneSettingName(true);
            };
            fetchData().catch((e) => {
                console.log("Error updating device label", e);
            });
        }
    }, [newName]);

    const onClickRename = () => {
        if (tempName === "") {
            alert("Please enter a valid name");
        } else {
            setNewName(tempName);
        }
    };

    const handleNameChange = (event: any) => {
        setTempName(event.target.value);
    };

    const lat = sensor?.location?.latitude ? sensor.location.latitude : null;
    const lon = sensor?.location?.longitude ? sensor.location.longitude : null;
    const gpsFormatted = formatGpsData(lon, lat);

    // const lastMeasurement = sensor?.lastMeasurement ? new Date(sensor.lastMeasurement).toLocaleString() : "No data";

    const sensorLabel = sensor?.label ? sensor.label : "Wireless Sensor";

    const battery = sensor?.battery;
    const soilSensor1 = sensor?.soilSensors ? sensor.soilSensors[0] : null;
    const soilSensor2 = sensor?.soilSensors ? sensor.soilSensors[1] : null;
    const gasSensor = sensor?.gasSensor;
    const lux = sensor?.lux;

    // Calculate soil sensor health based on the presence of data - The sensor returns 0 wvc if no data is present
    const soilSensor1Health = soilSensor1?.soilMoisture === 0 ? "Offline" : "Optimum";
    const soilSensor2Health = soilSensor2?.soilMoisture === 0 ? "Offline" : "Optimum";

    const lastMeasurement = sensor?.lastMeasurement ? new Date(sensor.lastMeasurement).toLocaleString() : "No data";

    const deviceLabel = device?.label ? device.label : "PheNode";

    if (sensorLoading) return <div className="loading-text">Loading...</div>;
    else {
        return (
            <Layout>
                <div className="w-layout-grid main-grid real-time">
                    <NavBlock col={5} fleet_view={false} gpsFormatted={gpsFormatted} lastMeasurement={lastMeasurement} selectedDevice={deviceLabel} />
                    <ScreenTitle />
                    <div id="phenode-status-div" className="phenode-status-div">
                        <Gps gpsFormatted={gpsFormatted} />
                        <AirSensor gasSensor={gasSensor} lux={lux} />
                        <BatteryAndSoilSensor
                            battery={battery}
                            soilMoisture1={soilSensor1?.soilMoisture}
                            soilMoisture2={soilSensor2?.soilMoisture}
                            soilSensor1Health={soilSensor1Health}
                            soilSensor2Health={soilSensor2Health}
                        />
                        <img
                            src={WirelessSensorGraphic}
                            loading="eager"
                            id="w-node-afc0e37e-ff63-608b-825e-3bfc65b0cfce-07ac755a"
                            alt=""
                            className="wireless-sensor-graphic"
                            style={{ maxWidth: "65%", marginTop: "-60px" }}
                        />
                        <WirelessSensorSelect wirelessSensorList={wirelessSensorList || []}></WirelessSensorSelect>
                        <div id="rename-sensor" className="w-layout-grid basic-account-grid">
                            <div
                                className="general-div-label settings"
                                style={{ marginTop: "-64px", gridArea: "2 / 1 / 3 / 2", alignSelf: "center", justifySelf: "center" }}>
                                {selectedWirelessSensor}
                            </div>
                            <div
                                id="w-node-_18cf0418-027a-861a-f0c1-4ed5243ca4a5-46ac755d"
                                className="general-div-label settings"
                                style={{ gridArea: "1 / 1 / 2 / 4", marginTop: "-34px" }}>
                                Rename this sensor
                            </div>
                            <CssTextField
                                value={tempName}
                                style={{
                                    marginTop: "-82px",
                                    gridArea: "2 / 2 / 3 / 3",
                                    alignSelf: "center",
                                    justifySelf: "start",
                                    width: "110%",
                                    maxWidth: "130%",
                                    minWidth: "90%",
                                }}
                                size="small"
                                onChange={handleNameChange}
                            />
                            <SubmitButton
                                label={"Rename"}
                                onClick={onClickRename}
                                sx={{ marginTop: "-82px" }}
                                disabled={null}
                                style={{ gridArea: "2 / 3 / 3 / 4", alignSelf: "center", justifySelf: "end", maxWidth: "170px" }}></SubmitButton>
                        </div>
                        <ScreenToggleButton
                            key="grafana-screen-button"
                            id="grafana-screen-button-ws"
                            className="grafana-screen-button"
                            to={grafanaUrl}
                            sx={{ gridRowStart: 7, width: "10rem" }}
                            icon={GrafanaActiveIcon}
                            activeIcon={GrafanaActiveIcon}
                            disabled={false}
                        />
                    </div>
                    <Grafana />
                </div>
            </Layout>
        );
    }
}
