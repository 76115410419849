import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

interface ImagesContainerProps {
    deviceImages: any[];
}

export default function ImagesContainer(props: ImagesContainerProps) {
    const { deviceImages } = props;

    const [selectedImage, setSelectedImage] = useState<any | null>(null);
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);

    useEffect(() => {
        if (deviceImages) {
            setSelectedImageIndex(0);
            setSelectedImage(deviceImages[selectedImageIndex]);
        }
    }, [deviceImages]);

    useEffect(() => {
        setSelectedImage(deviceImages[selectedImageIndex]);
    }, [selectedImageIndex]);

    return (
        <>
            <div id="image-date">{selectedImage?.timestamp ? new Date(selectedImage?.timestamp).toLocaleTimeString() + " " + new Date(selectedImage?.timestamp).toLocaleDateString() : ""}</div>
            <div id="image-title">{selectedImage?.filename}</div>
            <div id="image-container" style={{ marginBottom: "40px", marginTop: "40px" }}>
                <IconButton onClick={() => setSelectedImageIndex(selectedImageIndex - 1)} sx={{ marginRight: "1%", color: "white" }} size="large" disabled={!(selectedImageIndex > 0)}>
                    <ArrowBackIosOutlinedIcon />
                </IconButton>
                {selectedImage ? <img className="phenode-image" src={`data:image/jpeg;base64,${selectedImage?.base64encoded}`} alt="no-img"></img> : null}
                <IconButton onClick={() => setSelectedImageIndex(selectedImageIndex + 1)} sx={{ marginLeft: "1%", color: "white" }} size="large" disabled={!(selectedImageIndex < deviceImages?.length - 1)}>
                    <ArrowForwardIosOutlinedIcon />
                </IconButton>
            </div>
        </>
    );
}
