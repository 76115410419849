import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import NavBlock from "../../components/NavBlock";
import { useAppContext } from "../../store/AppContextProvider";
import ImagesContainer from "./ImagesContainer";
import ScreenTitle from "./ScreenTitle";
import { useKeycloak } from "@react-keycloak/web";
import { useDevice, useDeviceImages } from "../../services/api/swrHooks";
import { Device } from "../../utils/typesAndInterfaces";
import { formatGpsData } from "../../utils/helperFunctions";

export default function ImagesPage() {
    const { selectedDevice } = useAppContext();
    const { keycloak } = useKeycloak();

    const { data, deviceIsLoading, deviceIsError } = useDevice(keycloak.token, selectedDevice);
    const { images, imagesLoading } = useDeviceImages(keycloak.token, selectedDevice);

    const [ device, setDevice ] = useState<Device | null>(null);

    const [ deviceImages, setDeviceImages ] = useState<string[]>([]);

    const [token, setToken] = useState("");


    useEffect(() => {
        if (keycloak.token) {
            setToken(keycloak.token);
        }
    }, [keycloak.token]);

    useEffect(() => {
        if (data) {
            setDevice(data.device);
        }
    }, [data]);

    useEffect(() => {
        if (images) {
            setDeviceImages(images.images);
        }
    }, [images, selectedDevice]);



    const lat = device?.gps.latitude ? device.gps.latitude : null;
    const lon = device?.gps.longitude ? device.gps.longitude : null;
    const gpsFormatted = formatGpsData(lon, lat);

    const lastMeasurement = device?.lastMeasurement ? new Date(device.lastMeasurement).toLocaleString() : "No data";

    const deviceLabel = device?.label ? device.label : "PheNode";

    if (imagesLoading) return <div className="loading-text">Loading...</div>;
    return (
        <Layout>
            <div className="w-layout-grid main-grid variation data-download">
                <NavBlock col={5} fleet_view={false} gpsFormatted={gpsFormatted} lastMeasurement={lastMeasurement} selectedDevice={deviceLabel} downloadDataPage={true}/>
                <ScreenTitle />
                <ImagesContainer deviceImages={deviceImages}></ImagesContainer>
            </div>
        </Layout>
    );
}