import ReactDOM from "react-dom/client";
import React from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./styles/css/normalize.css";
import "./styles/css/components.css";
import "./styles/css/phenode-gui.css";
import "./styles/css/custom.css";
    
// NOTE: The <React.StrictMode> should be used for development, but it breaks the grafana embedding.
// TODO: Fix above issue
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
