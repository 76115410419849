import React, { useEffect, useCallback } from "react";
import { AppContextProvider, useAppContext } from "./store/AppContextProvider";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import NoMatch from "./components/NoMatch";
import DownloadDataPage from "./features/download-data";
import FleetPage from "./features/fleet";
import RealTimeDataPage from "./features/real-time";
import SettingsPage from "./features/settings";
import WirelessSensorPage from "./features/wireless-sensor";

import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import keycloak from "./Keycloak";
import PrivateRoute from "./utils/privateRoute";
import DataDownloadPreferencesPage from "./features/data-download-preferences";
import ImagesPage from "./features/images";
import RestrictedRoute from "./utils/RestrictedRoute";

import HttpsRedirect from "./components/HttpsRedirect";

function Main() {
    const { keycloak, initialized } = useKeycloak();

    if (!initialized) {
        return <div className="loading-text">Loading...</div>;
    }
    if (!keycloak.authenticated && keycloak) {
        keycloak.login();
    }

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <PrivateRoute>
                        <FleetPage />
                    </PrivateRoute>
                }
            />
            <Route
                path="/real-time/*"
                element={
                    <PrivateRoute>
                        <RealTimeDataPage />
                    </PrivateRoute>
                }
            />
            <Route
                path="/download-data"
                element={
                    <PrivateRoute>
                        <DownloadDataPage />
                    </PrivateRoute>
                }
            />
            <Route
                path="/download-data-preferences"
                element={
                    <PrivateRoute>
                        <RestrictedRoute>
                            <DataDownloadPreferencesPage />
                        </RestrictedRoute>
                    </PrivateRoute>
                }
            />
            <Route
                path="/settings"
                element={
                    <PrivateRoute>
                        <RestrictedRoute>
                            <SettingsPage />
                        </RestrictedRoute>
                    </PrivateRoute>
                }
            />
            <Route
                path="/images"
                element={
                    <PrivateRoute>
                        <ImagesPage />
                    </PrivateRoute>
                }
            />
            <Route
                path="/wireless-sensor"
                element={
                    <PrivateRoute>
                        <WirelessSensorPage />
                    </PrivateRoute>
                }
            />
            <Route
                path="*"
                element={
                    <PrivateRoute>
                        <NoMatch />
                    </PrivateRoute>
                }
            />
        </Routes>
    );
}

function App() {
    useEffect(() => {
        document.body.classList.add("body-2");
    }, []);

    return (
        <>
            <HttpsRedirect disabled={true}>
                <ReactKeycloakProvider authClient={keycloak}>
                    <AppContextProvider>
                        <BrowserRouter>
                            <Main />
                        </BrowserRouter>
                    </AppContextProvider>
                </ReactKeycloakProvider>
            </HttpsRedirect>
        </>
    );
}

export default App;
