import React from "react";
import LogOutButton from "./LogOutButton";
import "./username-and-log-out.modules.css";

interface UsernameAndLogOutProps {
    username: string;
}

export default function UsernameAndLogOut(props: UsernameAndLogOutProps) {
    const { username } = props;
    return (
        <div id="username-and-log-out" className="username-and-log-out">
            <div id="active-user-name" className="active-user-name gradient">
                {username}
            </div>
            <LogOutButton />
        </div>
    );
}
