import React from "react";

interface RainfallDataProps {
    hourlyRainfall: number | undefined;
    sensorHealth: string | undefined;
}

export default function Rainfall(props: RainfallDataProps) {
    const { hourlyRainfall: hourlyRainfall, sensorHealth } = props;

    return (
        <div id="w-node-c4f65bba-867f-8a03-6b8c-3fac95607c44-07ac755a" className="rainfall-sensor-measurements w-clearfix">
            <div id="w-node-c4f65bba-867f-8a03-6b8c-3fac95607c45-07ac755a" className="value-label">
                {"Hourly Rainfall:"}
            </div>
            <div id="w-node-c4f65bba-867f-8a03-6b8c-3fac95607c47-07ac755a" className="value align-right">
                {hourlyRainfall ? hourlyRainfall + " in" : ""}
            </div>
            <div id="w-node-c4f65bba-867f-8a03-6b8c-3fac95607c49-07ac755a" className="value-label">
                Sensor Health:
            </div>
            <div
                id="w-node-c4f65bba-867f-8a03-6b8c-3fac95607c4b-07ac755a"
                className={`value align-right ${sensorHealth === "Offline" ? "orange" : ""} ${sensorHealth === "Check" ? "magenta" : ""}`}>
                {sensorHealth}
            </div>
        </div>
    );
}
