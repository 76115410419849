import React from "react";
import { useLocation } from "react-router-dom";
import "./screen-toggle-button.modules.css";

interface ScreenToggleButtonProps {
    id: string;
    className: string;
    to: string;
    icon: string;
    activeIcon: string;
    disabled: boolean;
    sx?: any;
}

export default function ScreenToggleButton(props: ScreenToggleButtonProps) {
    const { id, className, to, icon, activeIcon, disabled, sx } = props;

    const location = useLocation();

    const isActive = to === location.pathname;

    return (
        <a
            id={id}
            className={`${className} w-button ${isActive ? "w--current" : ""}`}
            href={to}
            style={{
                ...sx,
                backgroundImage: `url(${isActive ? activeIcon : icon})`, pointerEvents: disabled ? "none": "auto"
            }}>
        </a>
    );
}
