import React from "react";
import Button from "../../../components/common/Button";


interface RenameButtonProps {
    label: string;
    sx?: object;
    [key: string]: unknown; //This is the line I have added
}

export default function SubmitButton(props: RenameButtonProps) {
    const { label, sx, ...rest } = props;
    return (
        <Button {...rest} className="submit-button" sx={{...sx, border: 1, borderColor: "#f59331", color: "#48f7f5", borderRadius: "2px", justifySelf: "center"}}>
            {label}
        </Button>
    );
}