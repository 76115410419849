import React from "react";
import { Link } from "react-router-dom";

interface cameraProps {
    lastImageSent: string | undefined;
    sensorHealth: string | undefined;
}

export default function ImagingSensor(props: cameraProps) {
    const { lastImageSent, sensorHealth } = props;

    let lastImageSentFormatted = "";
    if (lastImageSent) {
        lastImageSentFormatted = new Date(lastImageSent).toLocaleTimeString();
    }

    return (
        <div id="w-node-f8e14c55-05ec-1c53-7b9e-b5b62ca6a5b0-07ac755a" className="imaging-sensor-status w-clearfix">
            <div id="w-node-f8e14c55-05ec-1c53-7b9e-b5b62ca6a5b1-07ac755a" className="value-label">
                Last image sent:
            </div>
            <div id="w-node-f8e14c55-05ec-1c53-7b9e-b5b62ca6a5b3-07ac755a" className="value lj">
                {lastImageSentFormatted ? lastImageSentFormatted : ""}
            </div>
            <div id="w-node-f8e14c55-05ec-1c53-7b9e-b5b62ca6a5b5-07ac755a" className="value-label">
                Sensor Health:
            </div>
            <div
                id="w-node-f8e14c55-05ec-1c53-7b9e-b5b62ca6a5b7-07ac755a"
                className={`value lj ${sensorHealth === "Offline" ? "orange" : ""} ${sensorHealth === "Check" ? "magenta" : ""}`}>
                {sensorHealth ? sensorHealth : ""}
            </div>
            <Link id="w-node-f8e14c55-05ec-1c53-7b9e-b5b62ca6a5b1-07ac755b" to="/images" className="value-label" style={{color: "#f59331", textDecoration: "none"}}>
                See images
            </Link>
        </div>
    );
}
