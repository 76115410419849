import Keycloak from "keycloak-js";

import { SERVER_IP, TOKEN_EXPIRATION, KEYCLOAK_REALM, KEYCLOAK_CLIENT_ID } from "./services/api/api";

const keycloak = new Keycloak({
    url: "https://auth.phenode.cloud",
    realm: KEYCLOAK_REALM,
    clientId: KEYCLOAK_CLIENT_ID,
});

keycloak.onTokenExpired = () => {
    keycloak
        .updateToken(TOKEN_EXPIRATION)
        .then(() => {
            console.log("successfully get a new token");
        })
        .catch(() => {
            console.log("failed to refresh token");
        });
};
export default keycloak;
