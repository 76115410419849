// Format floats into Standard GPS format in degrees, minutes and seconds
export function formatGpsData(longitude: number | null, latitude: number | null) {
    if (longitude === null || latitude === null) {
        return "No data";
    }

    const lonAbs = Math.abs(longitude);
    const latAbs = Math.abs(latitude);

    let lonDeg = Math.floor(lonAbs);
    const lonMin = Math.floor((lonAbs - lonDeg) * 60);
    const lonSec = ((lonAbs - lonDeg - lonMin / 60) * 3600).toFixed(2);

    let latDeg = Math.floor(latAbs);
    const latMin = Math.floor((latAbs - latDeg) * 60);
    const latSec = ((latAbs - latDeg - latMin / 60) * 3600).toFixed(2);

    const latDirection = latitude < 0 ? "S" : "N";
    const lonDirection = longitude < 0 ? "W" : "E";

    latDeg = Math.abs(latDeg);
    lonDeg = Math.abs(lonDeg);

    return `${latDeg}°${latMin}'${latSec}"${latDirection} ${lonDeg}°${lonMin}'${lonSec}"${lonDirection}`;
}

export function mapDevices(devices: any) {
    if (!devices) {
        return null;
    }
    return devices.map((device: any) => {
        return {
            _id: device._id,
            externalDeviceId: device.deviceId,
            label: device.label,
        };
    });
}

export function mapWirelessSensors(sensors: any) {
    if (!sensors) {
        return null;
    }
    return sensors.map((device: any) => {
        return {
            _id: device._id,
            externalDeviceId: device.deviceId,
            label: device.label,
        };
    });
}
