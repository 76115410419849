import React from "react";
import "./device-info.modules.css";

interface DeviceInfoProps {
    gpsFormatted: string;
    lastMeasurement: string;
}

export default function DeviceInfo(props: DeviceInfoProps) {
    const { gpsFormatted, lastMeasurement } = props;

    return (
        <div id="device-info" className="device-info">
            <div id="last-measurement" className="text-block-65">
                Last measurements taken:
            </div>
            <div id="last-measurement-value" className="value medium">
                {lastMeasurement}
            </div>
            <div id="gps" className="text-block-65">
                GPS:
            </div>
            <div id="gps-value" className="value medium">
                {gpsFormatted}
            </div>
        </div>
    );
}
