import React, { useEffect } from "react";
import DeviceInfo from "./DeviceInfo";
import PhenodeSelect from "./PhenodeSelect";
import ScreenToggleButtons from "./ScreenToggleButtons";
import UsernameAndLogOut from "./UsernameAndLogOut";
import "./nav-block.modules.css";
import { useKeycloak } from "@react-keycloak/web";
import { useAppContext } from "../../store/AppContextProvider";
import { useMyDevices } from "../../services/api/swrHooks";
import { mapDevices } from "../../utils/helperFunctions";

interface NavBlockProps {
    col: number;
    fleet_view: boolean;
    gpsFormatted?: string;
    lastMeasurement?: string;
    selectedDevice?: string;
    downloadDataPage?: boolean;
}

export default function NavBlock(props: NavBlockProps) {
    const { col, fleet_view, gpsFormatted, lastMeasurement, selectedDevice, downloadDataPage } = props;

    const { keycloak } = useKeycloak();

    const username = keycloak.tokenParsed?.preferred_username;

    // Fetch device, deviceList inside the component
    const { deviceList, setDeviceList } = useAppContext();

    const { devicesData, devicesLoading } = useMyDevices(keycloak.token);

    useEffect(() => {
        if (devicesData) {
            const mappedDevices = mapDevices(devicesData.devices);
            if (mappedDevices !== null) {
                setDeviceList(mapDevices(devicesData.devices));
            }
        }
    }, [devicesData]);

    return (
        <div id={`nav-block-div__col-${col}`} className="nav-block-div">
            <div id="nav-block" className="nav-block">
                <img
                    src="https://assets.website-files.com/6271a8cb8adada1bfb230ccd/6271a8cb8adadadf13230cfa_PheNode%20Logo%20GUI-official.svg"
                    loading="lazy"
                    id="logo"
                    alt=""
                    className="phenode-logo"
                />
                {username ? <UsernameAndLogOut username={username} /> : <UsernameAndLogOut username={""}/>}
                <ScreenToggleButtons />
                {!fleet_view && deviceList && selectedDevice ? <PhenodeSelect deviceList={deviceList} downloadDataPage={downloadDataPage} /> : null}
                {!fleet_view && gpsFormatted && lastMeasurement ? <DeviceInfo gpsFormatted={gpsFormatted} lastMeasurement={lastMeasurement}/> : null}
            </div>
        </div>
    );
}
