import React from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../../../../store/AppContextProvider";
import { formatGpsData } from "../../../../utils/helperFunctions";
import MeasurementBlock from "./components/MeasurementBlock";
import "./fleet-list-item.modules.css";

interface FleetListItemProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    device: any;
}

export default function FleetListItem(props: FleetListItemProps) {
    const { device } = props;

    const { setSelectedDevice } = useAppContext();

    const gpsFormatted = formatGpsData(device.gps.longitude, device.gps.latitude);

    const onClick = () => {
        setSelectedDevice(device.deviceId);
    };

    return (
        <Link to="/real-time" onClick={onClick} className="link">
            <div id="fleet-div" className="fleet-div gradient option-2">
                <div className="fleet-div-label">{device.label}</div>
                <div id="date-block" className="date-block w-clearfix">
                    <div id="value-label" className="value-label">
                        Last measurements taken:
                    </div>
                    <div id="value" className="value lj">
                        {new Date(device.lastMeasurement).toLocaleString()}
                    </div>
                </div>
                <div id="measurement-block" className="measurement-block">
                    <div id="system-health-label" className="system-health-label">
                        Health:
                    </div>
                    <div
                        id="fleet-status-value"
                        className={`value fleet-status glow ${device.health === "Offline" ? "orange" : ""} ${
                            device.health === "Check" ? "magenta" : ""
                        }`}>
                        {device.health}
                    </div>
                </div>
                {/* <MeasurementBlock label="Temperature" value={device.airSensor.temperature ? device.airSensor.temperature + " °C": ""} /> */}
                {/* <MeasurementBlock label="Today's Rainfall:" value={device.rainfallSensor.hourlyRainfall ? device.rainfallSensor.hourlyRainfall + " mm": ""} /> */}
                {/* <MeasurementBlock label="Wind Speed:" value={device.windSensor.windSpeed ? device.windSensor.windSpeed + " km/h" : ""} /> */}
                {/* Use imperial units for US - Dropdown in the future?*/}
                <MeasurementBlock label="Temperature" value={device.airSensor.temperature ? (device.airSensor.temperature * 1.8 + 32).toFixed(2) + " F": ""} />
                <MeasurementBlock label="Hourly Rainfall:" value={device.rainfallSensor.hourlyRainfall ? (device.rainfallSensor.hourlyRainfall * 0.0393700787).toFixed(2) + " in": ""} />
                <MeasurementBlock label="Wind Speed:" value={device.windSensor.windSpeed ? (device.windSensor.windSpeed * 0.621371192).toFixed(2) + " mph" : ""} />
                <MeasurementBlock label="Battery:" value={device.battery.batteryPercent ? device.battery.batteryPercent + " %" : ""} />
                <MeasurementBlock label="GPS:" value={gpsFormatted} small={true} />
            </div>
        </Link>
    );
}
