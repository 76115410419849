import React from "react";
import "./screen-title.modules.css";

interface deviceLabelInterface {
    deviceLabel: string;
}

export default function ScreenTitle(props: deviceLabelInterface) {
    const { deviceLabel } = props;
    return (
        <div id="screen-title" className="screen-title real-time">
            <div id="screen-title__text" className="text-block-30">
                {deviceLabel} System Status
            </div>
        </div>
    );
}
