import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { SERVER_IP, DASHBOARD_ID } from "../../../../services/api/api";
import { useAppContext } from "../../../../store/AppContextProvider";
import { useDevice, useMySensors } from "../../../../services/api/swrHooks";
import { Device } from "../../../../utils/typesAndInterfaces";

export default function Grafana() {
    const { keycloak, initialized } = useKeycloak();

    const { selectedDevice } = useAppContext();

    const { data, deviceIsLoading, deviceIsError } = useDevice(keycloak.token, selectedDevice);
    const { sensorsData, sensorsLoading, sensorsError } = useMySensors(keycloak.token);

    const [device, setDevice] = useState<Device | null>(null);
    const [sensors, setSensors] = useState<string[] | null>(null);

    useEffect(() => {
        if (data) {
            setDevice(data.device);
        }
    }, [data]);

    useEffect(() => {
        if (sensorsData) {
            setSensors(sensorsData.sensors);
        }
    }, [sensorsData]);

    const deviceName = device?.deviceId ? device.deviceId : "PheNode";

    if (keycloak) {
        const sensorsUrl = sensors ? "&var-sensor=" + sensors.map((sensor: any) => sensor.externalSensorId).join("&var-sensor=") : "&var-sensor=";
        const url = `https://grafana.phenode.cloud/${DASHBOARD_ID}?orgId=1&kiosk=tv&auth_token=${keycloak.token}&refresh=30m&from=now-6h&to=now&var-device=${deviceName}${sensorsUrl}`;

        if (keycloak.authenticated)
            return (
                <div id="w-node-_473a3554-fab9-4dbf-cddf-310ef7914f1b-07ac755a" className="grafana-div-block" style={{marginTop: "50px", marginBottom: "50px"}}>
                    <iframe title="grafana iframe" src={url} width="100%" height="100%"></iframe>
                    <br></br>
                </div>
            );
        else
            return (
                <div id="w-node-_473a3554-fab9-4dbf-cddf-310ef7914f1b-07ac755a" className="grafana-div-block">
                    Unable to authenticate!
                </div>
            );
    } else
        return (
            <div id="w-node-_473a3554-fab9-4dbf-cddf-310ef7914f1b-07ac755a" className="grafana-div-block">
                Initializing Keycloak...
            </div>
        );
}
