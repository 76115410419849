import React from "react";

interface GpsDataProps {
    gpsFormatted: string | undefined;
}

export default function Gps(props: GpsDataProps) {
    const { gpsFormatted } = props;
    return (
        <div id="w-node-e8f813a7-8174-4db7-4f5e-e2bcd73ed8ad-71f130de" className="gps" style={{width: "90%"}}>
            <div id="w-node-_2cdbbb35-9595-c64b-f51d-0cfbe994f7f3-71f130de" className="value-label">GPS:</div>
            <div id="w-node-_5943aba1-8f31-09ae-418f-308a367b3da5-71f130de" className="value rj" style={{gridColumnStart: 2}}>{gpsFormatted}</div>
        </div>
    );  
}