import React, { useEffect, useState } from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import DownloadButton from "../DownloadButton";
import { Device } from "../../../utils/typesAndInterfaces";
import dayjs, { Dayjs } from "dayjs";

function CalendarIcon() {
    return <InsertInvitationIcon style={{ color: "white" }} />;
}

interface RangePickerProps {
    device?: Device;
    dataSelect: string;
}

export default function RangePicker(props: RangePickerProps) {
    const { device, dataSelect } = props;

    const [startTime, setStartTime] = useState<Dayjs | null>(dayjs().subtract(1, "day"));
    const [endTime, setEndTime] = useState<Dayjs | null>(dayjs());

    return (
        <>
            <DateTimePicker
                value={startTime}
                onChange={(newValue) => setStartTime(newValue)}
                components={{ OpenPickerIcon: CalendarIcon }}
                sx={{ border: 1, borderColor: "#f59331", borderRadius: "2px", "& input": { color: "#48f7f5", fontSize: "0.8rem", fontFamily: "Arial, Helvetica Neue, Helvetica, sans-serif" } }}></DateTimePicker>
            <DateTimePicker
                value={endTime}
                onChange={(newValue) => setEndTime(newValue)}
                components={{ OpenPickerIcon: CalendarIcon }}
                sx={{ border: 1, borderColor: "#f59331", borderRadius: "2px", "& input": { color: "#48f7f5", fontSize: "0.8rem", fontFamily: "Arial, Helvetica Neue, Helvetica, sans-serif" } }}></DateTimePicker>
            <DownloadButton startTime={startTime} endTime={endTime} deviceId={device?.deviceId || ""} label={device?.label || ""} dataSelect={dataSelect}></DownloadButton>
        </>
    );
}
