import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/Layout";
import NavBlock from "../../components/NavBlock";
import FleetList from "./FleetList";
import ScreenTitle from "./ScreenTitle";

import { useDevices, useMyDevices } from "../../services/api/swrHooks";
import { useAppContext } from "../../store/AppContextProvider";
import { useKeycloak } from "@react-keycloak/web";
import { mapDevices } from "../../utils/helperFunctions";

export default function FleetPage() {
    const { keycloak } = useKeycloak();

    const { deviceList, setDeviceList } = useAppContext();

    const { devicesData, devicesLoading } = useMyDevices(keycloak.token);

    const [ devices, setDevices ] = useState([]);

    useEffect(() => {
        if (devicesData) {
            const mappedDevices = mapDevices(devicesData.devices);
            if (mappedDevices !== null) {
                setDeviceList(mapDevices(devicesData.devices));
            }
            if (devicesData.success) {
                setDevices(devicesData.devices);
            }
        }
    }, [devicesData]);

    if (devicesLoading || !devicesData) return <div className="loading-text">Loading...</div>;
    else {
        return (
            <Layout>
                <div className="w-layout-grid main-grid variation my-fleet option-2">
                    <NavBlock col={5} fleet_view={true} />
                    <ScreenTitle />
                    <FleetList devices={devices} />
                </div>
            </Layout>
        );
    }
}
