import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import NavBlock from "../../components/NavBlock";
import { useAppContext } from "../../store/AppContextProvider";
import ScreenTitle from "../download-data/ScreenTitle";

import { useKeycloak } from "@react-keycloak/web";
import { useDevice } from "../../services/api/swrHooks";
import { Device } from "../../utils/typesAndInterfaces";
import { formatGpsData } from "../../utils/helperFunctions";
import DataDownloadPreferencesButton from "./DataDownloadPreferencesButton";
import DataDownloadContainer from "./DataDownloadContainer";

export default function DownloadDataPage() {

    const { selectedDevice } = useAppContext();
    const { keycloak } = useKeycloak();

    const { data, deviceIsLoading, deviceIsError } = useDevice(keycloak.token, selectedDevice);

    const [ device, setDevice ] = useState<Device | null>(null);
    
    useEffect(() => {
        if (data) {
            setDevice(data.device);
        }
    }, [data]);

    const lat = device?.gps.latitude ? device.gps.latitude : null;
    const lon = device?.gps.longitude ? device.gps.longitude : null;
    const gpsFormatted = formatGpsData(lon, lat);

    const lastMeasurement = device?.lastMeasurement ? new Date(device.lastMeasurement).toLocaleString() : "No data";

    const deviceLabel = device?.label ? device.label : "PheNode";
    const dev = device ? device : undefined;

    return (
        <Layout>
            <div className="w-layout-grid main-grid variation data-download">
                <NavBlock col={5} fleet_view={false} gpsFormatted={gpsFormatted} lastMeasurement={lastMeasurement} selectedDevice={deviceLabel} downloadDataPage={true}/>
                {/* <NavBlock col={5} fleet_view={true}/> */}
                <ScreenTitle />
                <DataDownloadPreferencesButton />
                <DataDownloadContainer device={dev}/>
            </div>
        </Layout>
    );
}
