import React, { useEffect } from "react";
import "./fleet-list.modules.css";
import FleetListItem from "./FleetListItem";

interface FleetListProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    devices: any[];
}

export default function FleetList(props: FleetListProps) {
    const { devices } = props;

    return (
        <div id="fleet-divs" className="fleet-divs option-2">
            {devices.map((device) => (
                <FleetListItem key={device.deviceId} device={device} />
            ))}
        </div>
    );
}
