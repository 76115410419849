import React, { useEffect, useRef, useState } from "react";
import Button from "../../../components/common/Button";
import { CSVLink, CSVDownload } from "react-csv";
import { fetchAllDeviceData, fetchAllImages, fetchDeviceHealthData, fetchDeviceSensorData, fetchUserPreferences, fetchWirelessSensorData } from "../../../services/api/api";
import { useKeycloak } from "@react-keycloak/web";
import dayjs, { Dayjs } from "dayjs";
import { useAppContext } from "../../../store/AppContextProvider";
import { useMySensors } from "../../../services/api/swrHooks";

interface DownloadButtonProps {
    label: string;
    deviceId: string;
    dataSelect: string;
    startTime?: Dayjs | null;
    endTime?: Dayjs | null;
}

async function downloadFile(respDownload: any, label: string) {
    if (respDownload.status === 404) {
        alert("No data available for specified time range!");
    }
    else {
        const blob = await respDownload.blob();
        const url = window.URL.createObjectURL(
            new Blob([blob]),
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            label + ".zip",
        );
    
        // Append to html link element page
        document.body.appendChild(link);
    
        // Start download
        link.click();
    
        // Clean up and remove the link
        link?.parentNode?.removeChild(link);
    }
}

export default function DownloadButton(props: DownloadButtonProps) {
    const { label, deviceId, dataSelect, startTime, endTime } = props;
    const { keycloak } = useKeycloak();

    const [outData, setCsvData] = useState([]);
    const [token, setToken] = useState("");
    const [loading, setLoading] = useState<boolean>(false);

    const { sensorsData, sensorsLoading, sensorsError, mutate } = useMySensors(keycloak.token);
    const { dataDownloadPreferences, setDataDownloadPreferences, wirelessSensorList, setWirelessSensorList } = useAppContext();

    const csvInstance = useRef<any>();

    useEffect(() => {
        if (sensorsData) {
            setWirelessSensorList(sensorsData.sensors);
            // console.log(wirelessSensorList);
        }
    }, [sensorsData]);

    // Load dataDownloadPreferences on page load
    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const response = await fetchUserPreferences(keycloak.token);
            if (response.status === 200) {
                // If there is an error, set the default preferences
                const data = await response.json();
                setDataDownloadPreferences({...data.dataDownloadPreferences, time: new Date()});  // This forces the preferences to actually trigger an update in the context
            }
            if (response.status === 404) {
                setDataDownloadPreferences({time: new Date()});  // This forces the preferences to actually trigger an update in the context
            }
        };
        fetchData().catch((e) => {
            console.log("Error fetching user preferences", e);
        });
        setLoading(false);
    }, []);

    useEffect(() => {
        if (outData.length !== 0 && csvInstance.current && csvInstance.current.link) {
            setTimeout(() => {
                if (csvInstance.current !== undefined) {
                    csvInstance.current.link.click();
                    setCsvData([]);
                }
            });
        }
    }, [outData]);

    useEffect(() => {
        if (keycloak.token) {
            setToken(keycloak.token);
        }
    }, [keycloak.token]);

    // Set data range and query data here
    const fetchData = async () => {
        // We don't need to have a PheNode selected in order to download data
        if (dataSelect === "wireless-sensor") {
            const newStartTime = startTime;
            const newEndTime = endTime;
            setLoading(true);

            console.log("Fetching wireless sensor data");
            const respDownload = await fetchWirelessSensorData(wirelessSensorList.map((sensor: any) => sensor.externalSensorId), token, newStartTime?.toISOString() || "", newEndTime?.toISOString() || "", dataDownloadPreferences);
            if (respDownload.status === 404) {
                alert("No data available for specified time range!");
            }
            else {
                downloadFile(respDownload, "wireless-sensor-data-" + new Date().getTime());
            }
            setLoading(false);
        }
        if (label) {
            setLoading(true);
            let resp = null;
            
            const newStartTime = startTime;
            const newEndTime = endTime;

            if (dataSelect === "sensor") {
                resp = await fetchDeviceSensorData(deviceId, token, newStartTime?.toISOString() || "", newEndTime?.toISOString() || "", dataDownloadPreferences);
            }
            if (dataSelect === "health") {
                resp = await fetchDeviceHealthData(deviceId, token, newStartTime?.toISOString() || "", newEndTime?.toISOString() || "", dataDownloadPreferences);
            }
            if (dataSelect === "all") {
                const respDownload = await fetchAllDeviceData(deviceId, token, startTime?.toISOString() || "", endTime?.toISOString() || "", dataDownloadPreferences);
                if (respDownload.status === 404) {
                    alert("No data available for specified time range!");
                }
                else {
                    downloadFile(respDownload, label);
                }
                // else {
                //     const blob = await respDownload.blob();
                //     const url = window.URL.createObjectURL(
                //         new Blob([blob]),
                //     );
                //     const link = document.createElement("a");
                //     link.href = url;
                //     link.setAttribute(
                //         "download",
                //         label + ".zip",
                //     );
                
                //     // Append to html link element page
                //     document.body.appendChild(link);
                
                //     // Start download
                //     link.click();
                
                //     // Clean up and remove the link
                //     link?.parentNode?.removeChild(link);
                // }
            }
            if (dataSelect === "images") {
                const respDownload = await fetchAllImages(deviceId, token, startTime?.toISOString() || "", endTime?.toISOString() || "", dataDownloadPreferences);
                if (respDownload.status === 404) {
                    alert("No images available for specified time range!");
                }
                else {
                    downloadFile(respDownload, label);
                }
                // else {
                //     const blob = await respDownload.blob();
                //     const url = window.URL.createObjectURL(
                //         new Blob([blob]),
                //     );
                //     const link = document.createElement("a");
                //     link.href = url;
                //     link.setAttribute(
                //         "download",
                //         label + ".zip",
                //     );
                
                //     // Append to html link element page
                //     document.body.appendChild(link);
                
                //     // Start download
                //     link.click();
                
                //     // Clean up and remove the link
                //     link?.parentNode?.removeChild(link);
                // }
            }
            if (resp) {
                const json = await resp.json();
                if (json.success) {
                    const outData = json.csvLines;
                    setCsvData(outData);
                }
                else {
                    alert("No data available for specified time range!");
                }
            }
            setLoading(false);
        }
    };

    if (loading) {
        return <div className="loading-text">Loading...</div>;
    }

    return (
        <>
            <Button
                onClick={() => fetchData()}
                id="download-button-grid-pos"
                className="download-button"
                sx={{ border: 1, borderColor: "#f59331", color: "#48f7f5", borderRadius: "2px" }}>
                {loading ? "Loading..." : "Download"}
            </Button>
            {outData.length !== 0 ? (
                <CSVLink data={outData} filename={label + "_" + dataSelect + "_data"} ref={csvInstance}>
                    Data
                </CSVLink>
            ) : null}
        </>
    );
}
