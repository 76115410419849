import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../../store/AppContextProvider";
import "../../wireless-sensor-modules.css";

interface WirelessSensorSelectProps {
    wirelessSensorList: any[];
    downloadDataPage?: boolean;
}

export default function WirelessSensorSelect(props: WirelessSensorSelectProps) {
    const { wirelessSensorList } = props;
    
    const { selectedWirelessSensor, setSelectedWirelessSensor } = useAppContext();

    const refreshPage = () => {
        window.location.reload();
    };
    const updateSelectedWirelessSensor = (event: any) => {
        setSelectedWirelessSensor(event.target.value);
        refreshPage();
    };
    
    return (
        <div id="wireless-sensor-select" className="phenode-select w-form" style={{gridArea: "1 / 3 / 2 / 5"}}>
            <form id="email-form" className="form-17">
                <select id="FieldDock-Select" name="FieldDock-Select-2" className="select-field-3 w-select" onChange={updateSelectedWirelessSensor} >
                    <option style={{background: "#3898ec"}} value="">Select Wireless Sensor...</option>
                    {wirelessSensorList.map((sensor) => (
                        <option style={{background: "#3898ec"}} key={sensor.externalSensorId} value={sensor.externalSensorId} selected={selectedWirelessSensor === sensor.externalSensorId ? true : false}>
                            {sensor.label}
                        </option>
                    ))}
                </select>
            </form>
        </div>
    );
}