import React from "react";
import "./measurement-block.modules.css";

interface MeasurementBlockProps {
    label: string;
    value: string;
    small?: boolean;
}

export default function MeasurementBlock(props: MeasurementBlockProps) {
    const { label, value, small } = props;
    return (
        <div id="measurement-block-detail" className="measurement-block w-clearfix">
            <div id="measurement-block__value-label" className="value-label small">
                {label}
            </div>
            <div className={`value glow ${small ? "small" : ""}`}>{value}</div>
        </div>
    );
}
