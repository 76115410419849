import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { SERVER_IP, WIRELESS_DASHBOARD_ID } from "../../../../services/api/api";
import { useAppContext } from "../../../../store/AppContextProvider";
import { useDevice, useMySensors } from "../../../../services/api/swrHooks";
import { Device } from "../../../../utils/typesAndInterfaces";
import "../../wireless-sensor-modules.css";

export default function Grafana() {
    const { keycloak, initialized } = useKeycloak();

    const { selectedWirelessSensor } = useAppContext();

    const { sensorsData, sensorsLoading, sensorsError } = useMySensors(keycloak.token);

    const [sensors, setSensors] = useState<string[] | null>(null);

    useEffect(() => {
        if (sensorsData) {
            // remove the selectedWirelessSensor from the list of sensors
            const sensors = sensorsData.sensors.filter((sensor: any) => sensor.externalSensorId !== selectedWirelessSensor);
            if (selectedWirelessSensor) {
                // Add the selectedWirelessSensor to the beginning of the list of sensors
                sensors.unshift(sensorsData.sensors.filter((sensor: any) => sensor.externalSensorId === selectedWirelessSensor)[0]);
            }
            if (sensors) {
                setSensors(sensors);
            }
        }
    }, [sensorsData]);

    if (keycloak) {
        const sensorsUrl = sensors ? "&var-sensor=" + sensors.map((sensor: any) => sensor.externalSensorId).join("&var-sensor=") : "&var-sensor=";
        const url = `https://grafana.phenode.cloud/${WIRELESS_DASHBOARD_ID}?orgId=1&kiosk=tv&auth_token=${keycloak.token}&refresh=30m&from=now-6h&to=now${sensorsUrl}`;

        if (keycloak.authenticated)
            return (
                <div className="grafana-div-block grafana-ws" style={{marginTop: "100px", marginBottom: "50px"}}>
                    <iframe title="grafana iframe" src={url} width="100%" height="100%"></iframe>
                    <br></br>
                </div>
            );
        else
            return (
                <div className="grafana-div-block grafana-ws" style={{marginTop: "100px"}}>
                    Unable to authenticate!
                </div>
            );
    } else
        return (
            <div className="grafana-div-block grafana-ws" style={{marginTop: "100px"}}>
                Initializing Keycloak...
            </div>
        );
}
