import React from "react";

interface AirSensorProps {
    gasSensor: {
        temperature: number | undefined;
        humidity: number | undefined;
        airQualityIndex: number | undefined;
    } | undefined;
    lux: number | undefined;
}

export default function AirSensor(props: AirSensorProps) {
    const { gasSensor, lux } = props;

    return (
        <div id="w-node-c1662213-68a6-49f1-eaf1-e90dd794aa1b-71f130de" className="system-measurements-ws wsn w-clearfix">
            <div id="w-node-c1662213-68a6-49f1-eaf1-e90dd794aa1c-71f130de" className="value-label">Temperature:</div>
            <div id="w-node-c1662213-68a6-49f1-eaf1-e90dd794aa1e-71f130de" className="value rj">{gasSensor?.temperature ? (gasSensor.temperature * 1.8 + 32).toFixed(2) + " F" : null}</div>
            <div id="w-node-c1662213-68a6-49f1-eaf1-e90dd794aa20-71f130de" className="value-label">Humidity:</div>
            <div id="w-node-c1662213-68a6-49f1-eaf1-e90dd794aa22-71f130de" className="value rj">{gasSensor?.humidity ? gasSensor.humidity + " %" : null}</div>
            <div id="w-node-c1662213-68a6-49f1-eaf1-e90dd794aa28-71f130de" className="value-label">Air Quality Index:</div>
            <div id="w-node-c1662213-68a6-49f1-eaf1-e90dd794aa2a-71f130de" className="value rj">{gasSensor?.airQualityIndex}</div>
            <div id="w-node-_62d17ef5-3ce6-36cc-a3f8-8f8d741f2def-71f130de" className="value-label">Illumination (lux):</div>
            <div id="w-node-_874a9cab-b3ff-28f7-f031-ab38fa648919-71f130de" className="value rj">{lux || null}</div>
            <div id="w-node-cf9373c4-4c9d-9017-40e1-e567f7c70ca2-71f130de" className="value-label">Sensor Health:</div>
            <div id="w-node-_208bca55-4250-b010-4524-b2963e5383a9-71f130de" className="value rj">Optimum</div>
        </div>
    );
}