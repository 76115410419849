import React from "react";

import { soilSensorInterface } from "../../../../utils/typesAndInterfaces";

export default function SoilSensor2(props: soilSensorInterface) {
    const { soilMoisture, soilTemperature, sensorHealth, calibratedCountsVwc, electricalConductivity, vwc } = props;
    return (
        <div id="w-node-aea83274-8f6a-8ad3-dc0a-ba52be0e421e-07ac755a" className="soil-sensor-measurements soil-sensor-2 w-clearfix">
            <div id="w-node-aea83274-8f6a-8ad3-dc0a-ba52be0e421f-07ac755a" className="value-label">
                Water Content:
            </div>
            <div id="w-node-aea83274-8f6a-8ad3-dc0a-ba52be0e4221-07ac755a" className="value lj">
                {vwc ? vwc + " %" : ""}
            </div>
            <div id="w-node-aea83274-8f6a-8ad3-dc0a-ba52be0e4223-07ac755a" className="value-label">
                Soil Temperature:
            </div>
            <div id="w-node-aea83274-8f6a-8ad3-dc0a-ba52be0e4225-07ac755a" className="value lj">
                {/* {soilTemperature ? soilTemperature + "°C" : ""} */}
                {soilTemperature ? (soilTemperature * 1.8 + 32).toFixed(2) + " F" : ""}
            </div>
            <div id="w-node-aea83274-8f6a-8ad3-dc0a-ba52be0e421f-07ac755b" className="value-label">
                Conductivity:
            </div>
            <div id="w-node-aea83274-8f6a-8ad3-dc0a-ba52be0e4221-07ac755b" className="value lj">
                {electricalConductivity ? electricalConductivity : ""}
            </div>
            {/*}
            <div id="w-node-aea83274-8f6a-8ad3-dc0a-ba52be0e422b-07ac755a" className="value-label">
                Sensor Health:
            </div>
            <div
                id="w-node-aea83274-8f6a-8ad3-dc0a-ba52be0e422d-07ac755a"
                className={`value lj ${sensorHealth === "Offline" ? "orange" : ""} ${sensorHealth === "Check" ? "magenta" : ""}`}>
                {sensorHealth ? sensorHealth : ""}
            </div>
            */} 
        </div>
    );
}
