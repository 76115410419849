import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import ScreenToggleButton from "../../../components/NavBlock/ScreenToggleButtons/ScreenToggleButton";
import RealTimeSettingsActive from "../../../assets/images/data-no-line-active.svg";
import RealTimeSettingsInactive from "../../../assets/images/data-no-line.svg";
import RealTimeSettingsDisabled from "../../../assets/images/data-no-line-disabled.svg";

export default function DataDownloadPreferencesButton() {
    const { keycloak } = useKeycloak();

    return (
        <div id="data-download-preferences-div" className="sub-screen-button-div button-right">
            <ScreenToggleButton
                key={"data-download-preferences-button"}
                id={"data-download-preferences-button"}
                className={"home-button w-button"}
                to={"/download-data-preferences"}
                icon={keycloak.tokenParsed?.roles.includes("phenode-editor") ? RealTimeSettingsInactive: RealTimeSettingsDisabled}
                activeIcon={RealTimeSettingsActive}
                disabled={!keycloak.tokenParsed?.roles.includes("phenode-editor")}
            />
            <div id="data-download-preferences-text" className="text-block-67">
                To Data Download Preferences
            </div>
        </div>
    );
}
