import React from "react";
interface systemInfoProps {
    batteryPercent: number | undefined;
    health: string | undefined;
    wifi: number | undefined;
    cellular: number | undefined;
}

function mapSignalStrength(signal: number | undefined) {
    if (signal === undefined) {
        return (
            <>
                <div className="signal-green inactive"></div>
                <div className="signal-green inactive"></div>
                <div className="signal-green inactive"></div>
                <div className="signal-green inactive"></div>
            </>
        );
    }

    if (signal === 4) {
        return (
            <>
                <div className="signal-green glow"></div>
                <div className="signal-green glow"></div>
                <div className="signal-green glow"></div>
                <div className="signal-green glow"></div>
            </>
        );
    } else if (signal === 3) {
        return (
            <>
                <div className="signal-green glow"></div>
                <div className="signal-green glow"></div>
                <div className="signal-green glow"></div>
                <div className="signal-green inactive"></div>
            </>
        );
    } else if (signal === 2) {
        return (
            <>
                <div className="signal-green glow"></div>
                <div className="signal-green glow"></div>
                <div className="signal-green inactive"></div>
                <div className="signal-green inactive"></div>
            </>
        );
    } else if (signal === 1) {
        return (
            <>
                <div className="signal-green glow"></div>
                <div className="signal-green inactive"></div>
                <div className="signal-green inactive"></div>
                <div className="signal-green inactive"></div>
            </>
        );
    } else {
        return (
            <>
                <div className="signal-green inactive"></div>
                <div className="signal-green inactive"></div>
                <div className="signal-green inactive"></div>
                <div className="signal-green inactive"></div>
            </>
        );
    }
}

export default function System(props: systemInfoProps) {
    const { batteryPercent, health, wifi, cellular } = props;
    return (
        <div id="w-node-_6bce96a8-f4f9-2919-86d1-b82d57d02271-07ac755a" className="w-layout-grid system-measurements w-clearfix">
            <div id="w-node-_84a770d4-d93e-1bc2-3fa3-abd7dc18f2e3-07ac755a" className="value-label">
                Battery:
            </div>
            <div id="w-node-_3f758dd9-38f3-3b52-1719-fee5b8ee9581-07ac755a" className="value lj">
                {batteryPercent} %
            </div>
            <div id="w-node-a69847a2-17e8-5b66-b63a-9c8865917876-07ac755a" className="value-label">
                Health:
            </div>
            <div id="w-node-a69847a2-17e8-5b66-b63a-9c8865917878-07ac755a" className="value lj">
                {health}
            </div>
            <div id="w-node-c9995f62-90f2-be86-4fae-c72936f0ea99-07ac755a" className="value-label rj">
                WiFi:
            </div>
            <div id="w-node-c9995f62-90f2-be86-4fae-c72936f0ea9b-07ac755a" className="cellular-signal-blocks">
                {mapSignalStrength(wifi)}
            </div>
            <div id="w-node-c9995f62-90f2-be86-4fae-c72936f0eaa0-07ac755a" className="value-label rj">
                Cellular:
            </div>
            <div id="w-node-c9995f62-90f2-be86-4fae-c72936f0eaa2-07ac755a" className="cellular-signal-blocks">
                {mapSignalStrength(cellular)}
            </div>
        </div>
    );
}
