import React, { useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../store/AppContextProvider";

interface restrictedRouteProps {
    children: JSX.Element;
}

export default function RestrictedRoute({ children }: restrictedRouteProps) {
    const { keycloak } = useKeycloak();

    const navigate = useNavigate();

    useEffect(() => {
        if (!keycloak.tokenParsed?.roles.includes("phenode-editor")) {
            setTimeout(() => {
                navigate(-1);  // Redirect the user to the previous page
            }, 2000);
        }
    }, [keycloak]);

    if (keycloak.tokenParsed?.roles.includes("phenode-editor")) {
        return children;
    } else {
        return (
            <div style={{ color: "white" }}>Restricted Area.<br/>Redirecting...</div>
        );
    }
}
