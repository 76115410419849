/**
 * SWR fetching reusable function hooks
 *
 * Data is fetched via API from the back-end.
 * API URL is specified by API_URL global constant.
 */

import useSWR from "swr";
import { API_URL } from "./api";
import { fetcherWithToken } from "./fetcher";

/**
 * Fetch all devices from DB via API.
 *
 * Data is revalidated every 5 seconds.
 *
 * @param userToken
 * @returns object
 */
export function useDevices(userToken: string | undefined) {
    const { data, error, mutate } = useSWR([`${API_URL}/devices`, userToken], fetcherWithToken, { refreshInterval: 30000 });
    const returnObject = {
        devicesData: data,
        devicesLoading: !error && !data,
        devicesError: error,
        mutate: mutate,
    };
    //console.info(returnObject);
    return returnObject;
}

/**
 * Fetch all devices from DB via API.
 *
 * Data is revalidated every 5 seconds.
 *
 * @param userToken
 * @returns object
 */
export function useMyDevices(userToken: string | undefined) {
    const { data, error, mutate } = useSWR([`${API_URL}/devices/my-devices`, userToken], fetcherWithToken, { refreshInterval: 30000 });
    const returnObject = {
        devicesData: data,
        devicesLoading: !error && !data,
        devicesError: error,
        mutate: mutate,
    };
    //console.info(returnObject);
    return returnObject;
}

/**
 * Fetch the device specified from DB via API.
 *
 * Data is revalidated every 5 seconds.
 *
 * @param userToken
 * @param jobId
 * @returns object
 */
export function useDevice(userToken: string | undefined, deviceId: string) {
    const { data, error } = useSWR([`${API_URL}/devices/${deviceId}`, userToken], fetcherWithToken, { refreshInterval: 30000 });
    return {
        data: data,
        deviceIsLoading: !error && !data,
        deviceIsError: error,
    };
}

/**
 * Fetch the specified device images from DB via API.
 *
 * Data is revalidated every 60 seconds.
 *
 * @param userToken
 * @param jobId
 * @returns object
 */
export function useDeviceImages(userToken: string | undefined, deviceId: string) {
    const { data, error } = useSWR([`${API_URL}/devices/${deviceId}/images`, userToken], fetcherWithToken, { refreshInterval: 60000 });
    return {
        images: data,
        imagesLoading: !error && !data,
        imagesError: error,
    };
}

/**
 * Fetch all sensors from DB via API.
 *
 * Data is revalidated every 5 seconds.
 *
 * @param userToken
 * @returns object
 */
export function useMySensors(userToken: string | undefined) {
    const { data, error, mutate } = useSWR([`${API_URL}/wireless-sensors/my-sensors`, userToken], fetcherWithToken, { refreshInterval: 30000 });
    const returnObject = {
        sensorsData: data,
        sensorsLoading: !error && !data,
        sensorsError: error,
        mutate: mutate,
    };
    return returnObject;
}

export function useWirelessSensor(userToken: string | undefined, sensorId: string) {
    const { data, error, mutate } = useSWR([`${API_URL}/wireless-sensors/${sensorId}`, userToken], fetcherWithToken, { refreshInterval: 30000 });
    const returnObject = {
        sensorData: data,
        sensorLoading: !error && !data,
        sensorError: error,
        mutate: mutate,
    };
    return returnObject;
}
