import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import RangePicker from "../RangePicker";
import { Device } from "../../../utils/typesAndInterfaces";

interface DataDownloadContainerProps {
    device?: Device;
}

export default function DataDownloadContainer(props: DataDownloadContainerProps) {
    const { device } = props;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div id="data-download-divs" className="download-divs">
                <div id="download-env-block-div" className="data-download-box style">
                    <div className="w-layout-grid data-download-grid">
                        <RangePicker device={device} dataSelect={"sensor"}></RangePicker>
                        <div id="download-env-block-label" className="general-div-label download-data-block">
                            Download Environmental Data
                        </div>
                    </div>
                </div>
                <div id="download-img-block-div" className="data-download-box style">
                    <div className="w-layout-grid data-download-grid">
                        <RangePicker device={device} dataSelect={"images"}></RangePicker>
                        <div id="download-img-block-label" className="general-div-label download-data-block">
                            Download PheNode Images
                        </div>
                    </div>
                </div>
                <div id="download-diag-block-div" className="data-download-box style">
                    <div className="w-layout-grid data-download-grid">
                        <RangePicker device={device} dataSelect={"health"}></RangePicker>
                        <div id="download-diag-block-label" className="general-div-label download-data-block">
                            Download System Diagnostics Data
                        </div>
                    </div>
                </div>
                <div id="download-all-block-div" className="data-download-box style">
                    <div className="w-layout-grid data-download-grid">
                        <RangePicker device={device} dataSelect={"all"}></RangePicker>
                        <div id="download-all-block-label" className="general-div-label download-data-block">
                            Download All Data
                        </div>
                    </div>
                </div>
                <div id="download-wireless-sensor-block-div" className="data-download-box style">
                    <div className="w-layout-grid data-download-grid">
                        <RangePicker device={device} dataSelect={"wireless-sensor"}></RangePicker>
                        <div id="download-wireless-sensor-block-label" className="general-div-label download-data-block">
                            Download Wireless Sensor Data
                        </div>
                    </div>
                </div>
            </div>
        </LocalizationProvider>
    );
}
