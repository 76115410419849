import React from "react";
import { ButtonBase, ButtonBaseProps } from "@mui/material";

interface ButtonProps extends ButtonBaseProps {
    children: JSX.Element | JSX.Element[] | string;
}

export default function Button(props: ButtonProps) {
    const { sx, children, ...rest } = props;
    return (
        <ButtonBase {...rest} sx={{ minWidth: 120, minHeight: 40, maxHeight: 40, color: "white", padding: "0px 16px", borderRadius: 1, ...sx }}>
            {children}
        </ButtonBase>
    );
}
