import React, { useEffect, useState } from "react";
import HomeActiveIcon from "../../../assets/images/Home-Icon_Active.svg";
import HomeInactiveIcon from "../../../assets/images/Home-Icon_Inactive.svg";
import RealTimeActiveIcon from "../../../assets/images/real-time-settings-active.svg";
import RealTimeInactiveIcon from "../../../assets/images/real-time-settings-inactive.svg";
import DownloadDataActiveIcon from "../../../assets/images/Download-Icon_Active.svg";
import DownloadDataInactiveIcon from "../../../assets/images/Download-Icon.svg";
import GrafanaActiveIcon from "../../../assets/images/Grafana-Icon_Active.svg";
import GrafanaInactiveIcon from "../../../assets/images/Grafana-Icon_Inactive.svg";
import SettingsActiveIcon from "../../../assets/images/settings-no-line---active.svg";
import SettingsInactiveIcon from "../../../assets/images/settings-no-line.svg";
import SettingsDisabledIcon from "../../../assets/images/settings-no-line---disabled.svg";
import WirelessSensorsActiveIcon from "../../../assets/images/wireless-no-line---active.svg";
import WirelessSensorsInactiveIcon from "../../../assets/images/wireless-no-line.svg";
import ScreenToggleButton from "./ScreenToggleButton";
import "./screen-toggle-buttons.modules.css";
import { useKeycloak } from "@react-keycloak/web";
import { useAppContext } from "../../../store/AppContextProvider";

import { SERVER_IP, DASHBOARD_ID } from "../../../services/api/api";
import { useDevice, useMySensors } from "../../../services/api/swrHooks";
import { Device } from "../../../utils/typesAndInterfaces";

const screenToggleButtons = [
    {
        id: "home-button",
        className: "home-button",
        to: "/",
        icon: HomeInactiveIcon,
        activeIcon: HomeActiveIcon,
        disabled: false,
    },
    {
        id: "status-screen-button w-button",
        className: "status-screen-button",
        to: "/real-time",
        icon: RealTimeInactiveIcon,
        activeIcon: RealTimeActiveIcon,
        disabled: false,
    },
    {
        id: "wireless-sensor-screen-button",
        className: "wireless-sensor-screen-button",
        to: "/wireless-sensor",
        icon: WirelessSensorsInactiveIcon,
        activeIcon: WirelessSensorsActiveIcon,
        disabled: false,
    },
    {
        id: "data-download-screen-button",
        className: "data-download-screen-button",
        to: "/download-data",
        icon: DownloadDataInactiveIcon,
        activeIcon: DownloadDataActiveIcon,
        disabled: false,
    },
    // {
    //     id: "grafana-screen-button",
    //     className: "grafana-screen-button",
    //     to: "/",
    //     icon: GrafanaInactiveIcon,
    //     activeIcon: GrafanaActiveIcon,
    //     disabled: false,
    // },
    {
        id: "settings-screen-button",
        className: "settings-screen-button",
        to: "/settings",
        icon: SettingsInactiveIcon,
        activeIcon: SettingsActiveIcon,
        disabled: false,
    },
];
export default function ScreenToggleButtons() {
    const { keycloak } = useKeycloak();

    const { selectedDevice } = useAppContext();

    const { data, deviceIsLoading, deviceIsError } = useDevice(keycloak.token, selectedDevice);
    const { sensorsData, sensorsLoading, sensorsError } = useMySensors(keycloak.token);

    const [ device, setDevice ] = useState<Device | null>(null);
    const [ sensors, setSensors ] = useState<string[] | null>(null);
    
    useEffect(() => {
        if (data) {
            setDevice(data.device);
        }
    }, [data]);

    useEffect(() => {
        if (sensorsData) {
            setSensors(sensorsData.sensors);
        }
    }, [sensorsData]);

    const deviceName = device?.deviceId ? device.deviceId : "PheNode";

    // if (keycloak.authenticated) {
    //     const sensorsUrl = sensors ? "&var-sensor=" + sensors.map((sensor: any) => sensor.externalSensorId).join("&var-sensor=") : "&var-sensor=";
    //     screenToggleButtons[4].to = `https://grafana.phenode.cloud/${DASHBOARD_ID}?orgId=1&kiosk=tv&auth_token=${keycloak.token}&refresh=30m&from=now-6h&to=now&var-device=${deviceName}${sensorsUrl}`;
    // }

    // Disable the settings button if the user is not logged in
    if (!keycloak.tokenParsed?.roles.includes("phenode-editor")) {
        screenToggleButtons[4].disabled = true;
        screenToggleButtons[4].icon = SettingsDisabledIcon;
    }

    return (
        <div id="screen-toggle-buttons" className="screen-toggle-buttons top">
            {screenToggleButtons.map((button) => (
                <ScreenToggleButton
                    key={button.id}
                    id={button.id}
                    className={button.className}
                    to={button.to}
                    icon={button.icon}
                    activeIcon={button.activeIcon}
                    disabled={button.disabled}
                />
            ))}
        </div>
    );
}
