import React, { ReactNode } from "react";
import GeneralSection from "./GeneralSection";
import GeneralContainer from "./GeneralContainer";

interface LayoutProps {
    children: ReactNode;
}

export default function Layout({ children }: LayoutProps) {
    return (
        <GeneralSection>
            <GeneralContainer>{children}</GeneralContainer>
        </GeneralSection>
    );
}
