import React from "react";

interface Atmos41DataProps {
    precipitation: number | undefined;
    solar: number | undefined;
    strikes: number | undefined;
    strikeDistance: number | undefined;
    metadataValue: number | undefined;
}

export default function Atmos41Sensor(props: Atmos41DataProps) {

    const { precipitation, solar, strikes, strikeDistance, metadataValue } = props;

    return (
        <div id="w-node-c4f65bba-867f-8a03-6b8c-3fac95607c44-07ac755b" className="rainfall-sensor-measurements w-clearfix">
            <div id="w-node-c4f65bba-867f-8a03-6b8c-3fac95607c45-07ac755a" className="value-label">
                Precipitation:
            </div>
            <div id="w-node-c4f65bba-867f-8a03-6b8c-3fac95607c47-07ac755a" className="value align-right">
                {precipitation ? Math.round(precipitation) + " mm/h" : null}
            </div>
            <div id="w-node-c4f65bba-867f-8a03-6b8c-3fac95607c49-07ac755a" className="value-label">
                Solar Radiation:
            </div>
            <div id="w-node-c4f65bba-867f-8a03-6b8c-3fac95607c4b-07ac755a" className="value align-right">
                {solar ? solar + " W/m^2" : null}
            </div>
            <div id="w-node-c4f65bba-867f-8a03-6b8c-3fac95607c49-07ac755b" className="value-label">
                Lightning Strike Count:
            </div>
            <div id="w-node-c4f65bba-867f-8a03-6b8c-3fac95607c4b-07ac755b" className="value align-right">
                {strikes ? Math.round(strikes) : null}
            </div>
            <div id="w-node-c4f65bba-867f-8a03-6b8c-3fac95607c49-07ac755c" className="value-label">
                Lightning Distance:
            </div>
            <div id="w-node-c4f65bba-867f-8a03-6b8c-3fac95607c4b-07ac755c" className="value align-right">
                {strikeDistance ? strikeDistance + " km" : null}
            </div>
        </div>
    );
}