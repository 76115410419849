import React from "react";
import { soilSensorInterface } from "../../../../utils/typesAndInterfaces";

export default function SoilSensor1(props: soilSensorInterface) {
    const { soilMoisture, soilTemperature, sensorHealth, calibratedCountsVwc, electricalConductivity, vwc } = props;

    return (
        <div id="w-node-_897d38e1-278a-9d40-0f5d-371ab296af1d-07ac755a" className="soil-sensor-measurements w-clearfix">
            <div id="w-node-_897d38e1-278a-9d40-0f5d-371ab296af1e-07ac755a" className="value-label">
                Water Content:
            </div>
            <div id="w-node-_897d38e1-278a-9d40-0f5d-371ab296af20-07ac755a" className="value lj">
                {vwc ? vwc + " %" : ""}
            </div>
            <div id="w-node-_897d38e1-278a-9d40-0f5d-371ab296af22-07ac755a" className="value-label">
                Soil Temperature:
            </div>
            <div id="w-node-_897d38e1-278a-9d40-0f5d-371ab296af24-07ac755a" className="value lj">
                {/* {soilTemperature ? soilTemperature + "°C" : ""} */}
                {soilTemperature ? (soilTemperature * 1.8 + 32).toFixed(2) + " F" : ""}
            </div>
            <div id="w-node-_897d38e1-278a-9d40-0f5d-371ab296af1e-07ac755b" className="value-label">
                Conductivity:
            </div>
            <div id="w-node-_897d38e1-278a-9d40-0f5d-371ab296af20-07ac755b" className="value lj">
                {electricalConductivity ? electricalConductivity : ""}
            </div>
            <div id="w-node-_18056379-ca46-1c9e-f0df-a9b4f627ee35-07ac755a" className="value-label">
                Sensor Health:
            </div>
            <div
                id="w-node-df84e779-367c-bc17-8ff6-224e2fc01dc5-07ac755a"
                className={`value lj ${sensorHealth === "Offline" ? "orange" : ""} ${sensorHealth === "Check" ? "magenta" : ""}`}>
                {sensorHealth ? sensorHealth : ""}
            </div>
        </div>
    );
}
